import { base } from "@theme-ui/presets";
import { merge } from "theme-ui";
const breakpoints = ["640px", "768px", "992px", "1200px"];

const headingStyles = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
};

// merge will deeply merge custom values with the
// unknown theme's defaults
export default merge(base, {
  colors: {
    muted: "#999",
    text: "#000",
    yellow: "#FFDF5D",
    yellowDark: "#ffd011",
    yellowDarker: "#FDD042",
    primary: "#FFDF5D",
    background: "#FFDF5D",
    blue: "#5289E7",
  },
  fonts: {
    // body: `"Platform", Helvetica, Arial, sans-serif`,
    body: `"ComicSans", "Comic Sans MS", "Comic Sans", cursive`,
    heading: `"Platform", Helvetica, Arial, sans-serif`,
    platform: `"Platform", Helvetica, Arial, sans-serif`,
    doge: `"ComicSans", "Comic Sans MS", "Comic Sans", cursive`,
  },
  fontSizes: [12, 16, 18, 20, 24, 28, 32, 36, 48],
  space: [0, 2, 4, 6, 8, 12, 16, 20, 24, 32, 36, 48, 64, 96, 128, 256, 512],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
    normal: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  breakpoints,
  sizes: {
    container: breakpoints,
  },
  text: {
    default: {
      color: "text",
      fontSize: [1, 2, 3],
    },
    valueProp: {
      fontFamily: "platform",
      fontSize: "24px",
      lineHeight: 1.2,
      letterSpacing: "0.",
    },
    h1: {
      ...headingStyles,
      fontSize: [5, 6, 7],
      mb: 6,
    },
    h2: {
      ...headingStyles,
      fontSize: [4, 5, 6],
      mb: 4,
    },
  },
  buttons: {
    primary: {
      minHeight: 79,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      px: 8,
      py: 6,
      fontFamily: "body",
      fontSize: "22px",
      lineHeight: "31px",
      fontWeight: "bold",
      color: "text",
      bg: "yellowDarker",
      cursor: `url('/coin.png') 25 24, pointer`,
      // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      // borderRadius: 3,
      transition: "background-color 250ms ease-out",

      textTransform: "uppercase",
      letterSpacing: ".09em",
      border: "2px solid #000000",
      boxShadow: "6px 6px 0px #000000",
      borderRadius: "1000px",

      "&:hover": {
        bg: "yellow",
      },
    },
  },
  styles: {
    root: {
      // uses the theme values provided above
      fontFamily: "body",
      fontWeight: "body",
      lineHeight: "body",
      margin: 0,
    },
  },
});
